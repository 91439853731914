import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forEach, isEmpty } from 'lodash';
import { ReactComponent as ImproveIcon } from '../../assets/images/icons/improve.svg';
import SubmitButton from '../forms/SubmitButton';
import ImproveTools from './ImproveTools';
import AddMoreDetails from './AddMoreDetails';
import { togglePickAnImagePage } from '../../app/features/editor/editorSlice';
import ImageEditorView from './ImageEditorView';
import {
  currentImageFile, formDataGenerationInpainting,
} from '../utils/utils';
import { generateImageInpaintingAPI, saveImageAPI } from '../../app/thunks/generation';

function LeftSectionImprove() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [detailsData, setDetailsData] = useState({
    prompt: '',
    nprompt: '',
  });

  const imageParams = useSelector((state) => state.editor.imageParams);
  const projectName = useSelector((state) => state.editor.projectName);

  const [img, setImg] = useState();

  useEffect(() => {
    const timer = setTimeout(() => {
      const imgFile = currentImageFile();
      setImg(imgFile);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const completeHandle = async () => {
    const file = currentImageFile();
    const options = JSON.stringify({
      describe: imageParams.describe,
      style_name: imageParams.style_name,
      ratio: imageParams.ratio || 'Square',
    });
    const formData = new FormData();
    formData.append('name', projectName);
    formData.append('image', file);
    formData.append('favorite', false);
    formData.append('public', true);
    formData.append('options', options);
    dispatch(saveImageAPI(formData)).then((res) => {
      navigate('/');
    });
  };

  const backToPickImage = () => {
    dispatch(togglePickAnImagePage('pick'));
  };

  const regenerateHandle = async () => {
    const imageEditor = ImageEditorView?.getEditor();
    const objects = imageEditor && imageEditor?._graphics?._objects;

    if (isEmpty(objects)) return;
    forEach(objects, (object) => {
      object.canvas.backgroundColor = 'black';
      object.canvas.backgroundImage = null;
      object.stroke = 'white';
      object.fill = 'white';
    });

    const file = currentImageFile();

    const params = {
      prompt: detailsData.prompt,
      nprompt: detailsData.nprompt,
      quantity: imageParams.quantity,
      image1: img,
      image2: file,
    };

    const formData = formDataGenerationInpainting(params);
    dispatch(togglePickAnImagePage('generate'));
    await dispatch(generateImageInpaintingAPI(formData));
    dispatch(togglePickAnImagePage('pick'));
  };

  const onDataChange = (key, value) => {
    setDetailsData((prevState) => ({ ...prevState, [key]: value }));
  };

  return (
    <div className="leftSectionEditorContainer">
      <div className="topWrapper">
        <div className="titleWrapper">
          <span className="imgIcon"><ImproveIcon /></span>
          <span className="titleText">
            {' '}
            Improve
            <span className="stageTxt">3/3</span>
          </span>
        </div>
        <ImproveTools />
        <AddMoreDetails
          onAddDetailChange={(res) => onDataChange('prompt', res.target.value)}
          onNegativePromptChange={(res) => onDataChange('nprompt', res.target.value)}
        />
        <SubmitButton title="Apply changes and regenerate" mode="white-mode" onClick={regenerateHandle} icon="generate-icon.svg" />
        <SubmitButton title="Complete" onClick={completeHandle} />
        <SubmitButton title="Back" mode="white-mode" onClick={backToPickImage} />
      </div>
    </div>
  );
}

export default LeftSectionImprove;
