import { createAsyncThunk } from '@reduxjs/toolkit';
import { map } from 'lodash';
import { toast } from 'react-toastify';
import { thunkErrorHandler } from './thunkError';
import generationApi from '../api/generation';
import { togglePickAnImagePage } from '../features/editor/editorSlice';

export const generationImageAPI = createAsyncThunk(
  'generation/generateImageApi',
  async (data, { thunkAPI, dispatch }) => {
    try {
      dispatch(togglePickAnImagePage('generate'));
      const generationRes = await generationApi.generateImage(data);

      const images = await Promise.all(map(generationRes.data, async (image) => {
        const fetchedImagesRes = await generationApi.getGeneratedImages(image);
        const url = URL.createObjectURL(fetchedImagesRes.data);
        return url;
      }))
        .then((fetchedImages) => {
          dispatch(togglePickAnImagePage('pick'));
          return fetchedImages;
        })
        .catch((err) => {
          console.log(err, 'Error');
          dispatch(togglePickAnImagePage('edit'));
          toast.error(err);
        });
      return images;
    } catch (err) {
      console.error('Error generating image', err);
      toast.error(err?.messages?.message);
      return thunkErrorHandler(err, thunkAPI.rejectWithValue);
    }
  },
);

export const generateImageInpaintingAPI = createAsyncThunk(
  'generation/generateImageInpaintingApi',
  async (data, { thunkAPI, dispatch }) => {
    try {
      dispatch(togglePickAnImagePage('generate'));
      const generationRes = await generationApi.generateImageInpainting(data);
      const images = await Promise.all(map(generationRes.data, async (image) => {
        const fetchedImagesRes = await generationApi.getGeneratedImages(image);
        const url = URL.createObjectURL(fetchedImagesRes.data);
        return url;
      }))
        .then((fetchedImages) => {
          dispatch(togglePickAnImagePage('pick'));
          return fetchedImages;
        })
        .catch((err) => {
          console.log(err, 'Error');
          dispatch(togglePickAnImagePage('edit'));
          toast.error(err);
        });
      return images;
    } catch (err) {
      console.error('Error generating image', err);
      toast.error(err?.messages?.message);
      return thunkErrorHandler(err, thunkAPI.rejectWithValue);
    }
  },
);

export const getGeneratedImagesAPI = createAsyncThunk(
  'generation/getGeneratedImagesApi',
  async (data, { thunkAPI, dispatch }) => {
    try {
      const res = await generationApi.getGeneratedImages(data);

      return res.data;
    } catch (err) {
      return thunkErrorHandler(err, thunkAPI.rejectWithValue);
    }
  },
);

export const saveImageAPI = createAsyncThunk(
  'generation/saveImage',
  async (data, { thunkAPI, dispatch }) => {
    try {
      const res = await generationApi.saveImage(data);
      return res.data;
    } catch (err) {
      return thunkErrorHandler(err, thunkAPI.rejectWithValue);
    }
  },
);
